import { Link } from "gatsby"
import React, { Component } from 'react'
import loadable from '@loadable/component'

import Loader from "../components/loader";

const Head = loadable(() => import("../components/head"));
const InnerLayout = loadable(() => import('../components/Innerlayout'));
const Project = loadable(() => import("../components/project"));
const CountDetails = loadable(() => import("../components/countdetails"));
const Testimonials = loadable(() => import("../components/testimonials"));

const schema = {
    "@context": "http://schema.org/",
    "@type": "product",
    "image": "https://softtik.com/images/nft-collection/invest-section-8.webp",
    "name": "Top 3D NFT Game Development Company",
    "description": "Softtik is the most sought-after 3D NFT game development company with knowledgeable professionals offering high-end solutions and services.",
    "brand": "Softtik Technologies",
    "aggregateRating": {
        "@type": "AggregateRating",
        "ratingValue": "4.8",
        "reviewCount": "133"
    }
}

export class ThreeDNFTGame extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isVideo: false,
        }
    }

    handleBannerVideo = () => {
        let { isVideo } = this.state;
        let bannerVideo = document.getElementById('banner-video');
        if (!isVideo) {
            bannerVideo.play();
            this.setState({ isVideo: true });
        }
        else {
            bannerVideo.pause();
            bannerVideo.currentTime = 0;
            this.setState({ isVideo: false });
        }
    };

    onScroll = () => {
        const { pageYOffset } = window;
        let bannerVideo = document.getElementById('banner-video');
        if (pageYOffset > 0) {
            bannerVideo.pause();
            bannerVideo.currentTime = 0;
            this.setState({ isVideo: false });
        }
    };

    handleVideoEnd = () => {
        let bannerVideo = document.getElementById('banner-video');
        bannerVideo.pause();
        bannerVideo.currentTime = 0;
        this.setState({ isVideo: false });
    };

    render() {

        return (
            <>
                <InnerLayout header='Services' fallback={<Loader />}>
                    <Head
                        type="Adds"
                        fallback={<Loader />}
                        schemaMarkup={schema}
                        path="/3d-nft-game-development-services/"
                        title="3D NFT Game Development Company - Softtik Technologies"
                        thumbnail="https://softtik.com/images/nft-collection/invest-section-8.webp"
                        description="Softtik is the most sought-after 3D NFT game development company with knowledgeable professionals offering high-end solutions and services."
                    />
                    <main>
                        <div id="wrapper">
                            <div className="collection-page game-development-page">
                                {/* Banner */}
                                <section className="banner-sec services-banner-bg" id="book-an-appointment">
                                    <div className="banner-content">
                                        <div className="auto-container">
                                            <div className='row'>
                                                <div className="col-lg-8 col-md-12 col-sm-12 ">
                                                    <div className='banner-text wow fadeIn' data-wow-delay="0.3s">
                                                        <h1>3D NFT Game Development</h1>
                                                        <p>If Gaming is Your Passion,<br />
                                                            You have landed in the right direction!<br />
                                                            Mind-boggling & Feature-Rich Gaming Solutions <br />
                                                            To Entice Users to Play More and Earn More<br />
                                                            Avail Complete Range of 3D NFT Game Development Services </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-12 col-sm-12 ">
                                                    <div className='form-inner'>
                                                        <div className='form-area' style={{ zIndex: '0' }}>
                                                            <iframe src="https://contact-banner-softtik.gr8.com/"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='about-sec' id="about">
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='about-text col-lg-6 col-md-12'>
                                                    <div className='about-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='sec-title'>
                                                            <h2>Transform Your 3D NFT Gaming Ideas into Reality With Us</h2>
                                                            <p>The development of 3D NFT games is a massive industry that is still developing and showing no signs of slowing down. The purpose of creating a 3D NFT game aims to generate unique and appealing graphics and animation that will leave a lasting impact on the player. Well-designed games are easy to play and can hold the attention of a large number of players. These games stand out and keep gamers coming back for more.</p>
                                                            <p>It is recommended that you employ a professional game development business to make your gaming dreams a reality. The corporation must ensure that its games are top-of-the-line in visuals, gameplay, and user interface.</p>
                                                            <p>We are known for our out-of-the-box thinking and approach as a premier 3D NFT Game  Development Company. We offer fantastic gaming strategies to help you realize your vision. Our goal is to give the most refined product to our customers, and we follow an agile development strategy to ensure that we produce the best.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='about-img col-lg-6 col-md-12'>
                                                    <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/2d-game-development-img.webp' alt="About Image" />
                                                    </div>
                                                </div>
                                                <div className='about-text col-lg-12 col-md-12'>
                                                    <div className='about-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <ul className="list-about style-two">
                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">

                                                                    <p>Softtik provides expert 3D game design and development services for all modern platforms. Our team of skilled experts in gaming art, design, and programming will provide a suitable backdrop to your project from concept to release.</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">
                                                                    <p>In recent years, the NFT space has grown at an exponential rate. NFTs are now being integrated into games, bringing the gaming experience to a new level. We have the best staff and technical prowess to create amazing games for you.</p>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <span className="icon"><img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/logo-icon.webp' alt='softtiktech Image' /></span>
                                                                <div className="text-box">
                                                                    <p>We concentrate on research to supply you with cutting-edge solutions and services. We have a research and development section that monitors current trends to recommend the best solutions for particular projects.</p>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Services */}
                                    <section className='services-sec p2egame-development' >
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='col-12'>
                                                    <div className='sec-title text-center'>
                                                        <h2>Our  NFT Game Development Services</h2>
                                                    </div>
                                                </div>
                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-6.webp' alt='NFT Collection' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>Complete Cycle NFT Game Development</h3>
                                                            <p>We have the right skill-set and experience to assist you in entering the gaming industry with our most satisfactory NFT gaming platform development. We can provide addictive and fun-to-play games that attract gamers worldwide.</p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner stye-two wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-7.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>2D/3D NFT Game Development</h3>
                                                            <p>Our trained and skilled team of developers can aid you in minting unique in-game 2D/3D NFTs. One of the most significant advantages of NFTs is that gamers do not need to rely on third-party payment methods to obtain game assets.</p>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-8.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>VR NFT Game Development</h3>
                                                            <p>VR has become a flag-bearer for the immersive and more realistic gaming experience. We can integrate VR compatibility with our leading NFT gaming platform development services. Get in touch with us to do wonders with this latest technology.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner stye-two wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-9.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>NFT Game Launchpad Development</h3>
                                                            <p>The popularity and importance of the launchpad have been increasing due to its efficient project listing and launching. We can develop a future-ready and high-end NFT game launchpad with our years of experience and knowledge.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='service-box col-lg-4 col-md-6 col-sm-12'>
                                                    <div className='service-inner wow fadeIn' data-wow-delay="0.3s">
                                                        <div className='icon-box'>
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/marketplace-icon-10.webp' alt='NFT Marketplace' />
                                                        </div>
                                                        <div className='text-box'>
                                                            <h3>DAO Development </h3>
                                                            <p>DAO is the ultimate key solution to generating funding for the development of your  games. We can develop autonomous, reliable, and efficient DAOs that assist the community in taking back the control to make any changes in the game through DAO voting.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 text-center">
                                                    <Link to={`#book-an-appointment`} title="Get A Free Quote" className="stripped btn-style-four" stripHash>
                                                        Get A Free Quote
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </section>

                                {/* Cases */}
                                <section className='featured-area-sec twod-game-features' >
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='col-12'>
                                                <div className='sec-title text-center'>
                                                    <h2>Key Features Of A 3D NFT Gaming Platform</h2>
                                                    <p>Our team consists of talented developers with extensive experience in 3D NFT game development services such as token creation and platform development. We would be delighted to provide your company with a once-in-a-lifetime opportunity to develop your 3D NFT game to connect with thousands of users around the globe.<br /><br />Our advanced solutions will be feature-rich, secure, and reliable. Let’s explore the key features of the 3D NFT gaming platform.</p>
                                                </div>

                                                <div className="featured-box wow fadeIn" data-wow-delay="0.3s">
                                                    <div className="img-box text-center">
                                                        <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/feature-img-8.webp' alt="Features of Our NFT Development" />
                                                        <div className="featured-block featured-block-7">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-35.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-1">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-34.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-2">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-36.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-3">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-37.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-4">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-52.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-5">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-41.webp' alt="Features of Our NFT Development" />
                                                        </div>

                                                        <div className="featured-block featured-block-6">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-42.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-8">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-38.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-9">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-39.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                        <div className="featured-block featured-block-10">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/prog-53.webp' alt="Features of Our NFT Development" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                {/* Roadmap Section */}
                                <section id="roadmap" className="roadmap roadmap-ad-page">
                                    <div className="auto-container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="sec-title text-center">
                                                    <h2>Our 3D NFT Game Development Process</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="auto-container">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="timeline">
                                                    <div className="timeline-container left">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-18.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Business Consultation</h4>
                                                                    <p>The initial step of the 3D NFT game development process is to create and adjust the game plan, workflow, and a clear roadmap. Our team discusses and analyzes the client’s business expectations and then moves to draw a proper layout.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    1
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container right">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-19.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>3D NFT Game Wireframe</h4>
                                                                    <p>Once the client agrees to the initial plan, we move toward the next step for developing 3D NFT games. Our artists craft astonishing 3D designs, and the rest of the team creates game documents, storyboards, and concept art in this phase.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    2
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container left">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-20.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>3D NFT Game Development</h4>
                                                                    <p>Our qualified developers work to implement the crypto integration and code the whole project. We have a professional team of more than 50+ developers with years of experience, and they know what to do. Reach out to us for your game development.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    3
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container right">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-21.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Testing & Deployment</h4>
                                                                    <p>We have an expert team of quality analysts (QA) who check the whole coding to find errors and lags. We only deploy the project if our quality analysts clear the project.  QA team keeps an eye on and examines the entire project from start to finish.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    4
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="timeline-container left ">
                                                        <div className="content">
                                                            <div className="inner-timeline">
                                                                <div className="icon">
                                                                    <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/nft-collection/process-icon-22.webp' alt='NFT Marketplace' />
                                                                </div>
                                                                <div className="text-box">
                                                                    <h4>Support & Maintenance</h4>
                                                                    <p>We will collaborate closely with you and take your opinions and recommendations into account to create a product that meets and exceeds your expectations. Our team will be in touch with you to provide support until the project becomes a success story.</p>
                                                                </div>
                                                            </div>
                                                            <div class="go-corner">
                                                                <div class="go-arrow">
                                                                    5
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 text-center">
                                                <Link to={`#book-an-appointment`} title="Book an Appointment" className="stripped btn-style-three mt-5" stripHash>
                                                    Book an Appointment
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </section >

                                <section className="banner-counter">
                                    <CountDetails />
                                </section>

                                {/* testimonials */}
                                <section className='testimonials-sec'>
                                    <Testimonials />
                                </section>
                                {/* Portfolio */}
                                <section className='portfolio-sec services-projects' >
                                    <Project />
                                </section>
                                {/* Technology */}
                                <section className='technology-sec ad-page-technology' id="technology">
                                    <div className='auto-container'>
                                        <div className='row'>
                                            <div className='technology-text col-lg-12 col-md-12'>
                                                <div className='technology-inner wow fadeIn text-center' data-wow-delay="0.3s">
                                                    <div className='sec-title'>
                                                        <h2>Technology Stack</h2>
                                                    </div>
                                                    <p>We ensure the highest quality development services & solutions by using a wide range of latest<br /> technologies. We’ve a certified and experienced team of developers that can provide cutting edge<br /> solutions by using this technology stack.</p>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-1.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-2.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-3.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-4.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-5.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className='img-inner wow fadeIn' data-wow-delay="0.3s">
                                                            <img className='lazyload' src='https://assets-softtik.s3.us-east-2.amazonaws.com/images/services-add-pages/technology-stack-img-6.webp' alt='technology-img' />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className='get-intouch-sec ad-page-get-intouch' id="get-intouch">
                                    <div className='get-intouch-secs'>
                                        <div className='auto-container'>
                                            <div className='row'>
                                                <div className='form-box col-lg-6 col-md-12'>
                                                    <div className='form-inner'>
                                                        <div className='sec-title text-center'>
                                                            <h2>Meeting with us</h2>
                                                            <p>Softtik has the legacy of offering perfectly-suited DEX solutions to clients worldwide. We are up-to-date with the latest trends in the market and know how to create a platform that provides value to our clients.  Book an appointment with us to discuss your idea; our experts will be happy to help you.</p>
                                                            <Link to={`#book-an-appointment`} title="Book FREE SESSION" className="stripped btn-style-four" stripHash>
                                                                Book FREE SESSION
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='calendly-box col-lg-6 col-md-12'>
                                                    <div className='calendly-inner'>
                                                        <div className='calendly-area'>
                                                            <iframe className="calendly-inline-widget" src="https://calendly.com/softtik-technologies?hide_gdpr_banner=1" loading="lazy" width="520px" height="630px"></iframe>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </main>
                </InnerLayout >
            </>
        )
    };
};

export default ThreeDNFTGame;